import { observable, action } from 'mobx';

class CalendarStore {
  @observable availability = [];

  @observable availabilityConsultLoc = '';

  @observable availabilityProviderId = '';

  @observable calendarView = 'dayGridMonth';

  @observable closeIconColor = false;

  @observable date = '';

  @observable end = null;

  @observable event = null;

  @observable events = [];

  @observable facilities = [];

  @observable filteredEvents = [];

  @observable filterGroup = 'telemed';

  @observable isModalOpen = false;

  @observable modalMode = 'create';

  @observable providers = [];

  @observable start = null;

  @observable telemedWorkShifts = [];

  @action addFacility = facility => {
    const facilityExists = this.facilities.find(f => f._id === facility._id);
    if (!facilityExists) {
      this.facilities = [...this.facilities, facility];
    }
  };

  @action addProvider = provider => {
    const providerExists = this.providers.find(p => p._id === provider._id);
    if (!providerExists) {
      this.providers = [...this.providers, provider];
    }
  };

  @action getProviderIds = () => {
    return this.providers.map(p => p._id);
  };

  @action getFacilityIds = () => {
    return this.facilities.map(p => p._id);
  };

  @action addTelemedWorkShift = shift => {
    const shiftExists = this.telemedWorkShifts.find(s => s === shift);
    if (!shiftExists) {
      this.telemedWorkShifts = [...this.telemedWorkShifts, shift];
    }
  };

  @action clearFilter = () => {
    this.facilities = [];
    this.providers = [];
    this.telemedWorkShifts = [];
  };

  @action closeModal = () => {
    this.isModalOpen = false;
  };

  @action openModal = (
    modalMode,
    { date = '', availability = [], event = null, closeIconColor = '' },
  ) => {
    this.isModalOpen = true;
    this.availability = availability;
    this.date = date;
    this.event = event;
    this.modalMode = modalMode;
    this.closeIconColor = closeIconColor;
  };

  @action removeFacility = _id => {
    this.facilities = this.facilities.filter(f => f._id !== _id);
  };

  @action removeProvider = _id => {
    this.providers = this.providers.filter(p => p._id !== _id);
  };

  @action removeTelemedWorkShift = shift => {
    this.telemedWorkShifts = this.telemedWorkShifts.filter(s => s !== shift);
  };

  @action setAvailabilityConsultLoc = consultLoc => {
    this.availabilityConsultLoc = consultLoc;
  };

  @action setAvailabilityProviderId = providerId => {
    this.availabilityProviderId = providerId;
  };

  @action setCalendarView = calendarView => {
    this.calendarView = calendarView;
  };

  @action setDates = (start, end) => {
    this.start = start;
    this.end = end;
  };

  @action setEvent = event => {
    this.event = event;
  };

  @action setEvents = events => {
    this.events = events;
  };

  @action setFilterGroup = filterGroup => {
    this.filterGroup = filterGroup;
  };

  @action getFilterGroup = () => {
    return this.filterGroup;
  };
}

export default CalendarStore;
