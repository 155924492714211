import throttle from 'lodash/throttle';
import { reaction } from 'mobx';
import getConfig from 'next/config';
import Router from 'next/router';
import rootMobXStores from 'core/stores';

const { activityTimeoutMins } = getConfig().publicRuntimeConfig;

function logOutIfInactive() {
  const lastActive = parseInt(localStorage.getItem('lastActive') || '0', 10);
  console.log(activityTimeoutMins);
  const expiryDate = lastActive + activityTimeoutMins * 60000;
  const now = Date.now();
  if (expiryDate < now) {
    console.log('logging out due to inactivity');
    Router.push('/account/logout', '/account/logout');
  } else {
    setTimeout(logOutIfInactive, now - expiryDate);
  }
}

const refreshActivity = throttle(
  () => {
    if (process.browser) {
      localStorage.setItem('lastActive', `${Date.now()}`);
      setTimeout(logOutIfInactive, activityTimeoutMins * 60000);
    }
  },
  5000,
  { trailing: true },
);

if (process.browser) {
  reaction(() => rootMobXStores.authStore.authToken, refreshActivity);
}

export default refreshActivity;
