import React, { Component } from 'react';
import PropTypes from 'prop-types';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { Query } from 'react-apollo';
import { inject, observer } from 'mobx-react';
import MyProfileQuery from 'graphql/gql/myProfile';

export default function WithMyProfile(Comp) {
  /* eslint-disable react/prefer-stateless-function */
  @inject('authStore')
  @observer
  class WithMyProfileQuery extends Component {
    static propTypes = {
      authStore: PropTypes.shape({
        setProfile: PropTypes.func.isRequired,
      }).isRequired,
    };

    render() {
      return (
        <Query query={MyProfileQuery}>
          {({ loading, data, refetch }) => {
            const props = {
              ...this.props,
              refetchMyProfile: refetch,
              isLoadingMyProfile: loading,
            };

            if (!loading && data && data.myProfile) {
              const { myProfile } = data;
              props.myProfile = myProfile;
            }

            return <Comp {...props} />;
          }}
        </Query>
      );
    }
  }

  hoistNonReactStatic(WithMyProfileQuery, Comp);

  return WithMyProfileQuery;
}
