import gql from 'graphql-tag';
import CommonAccount from '../fragments/account';

export default gql`
  query MyProfile {
    myProfile {
      ...CommonAccount
    }
  }
  ${CommonAccount}
`;
