import { observable, action } from 'mobx';

class UIStore {
  @observable callsCount = 0;

  @observable emrEncounter = null;

  @observable isGlobalModalOpen = false;

  @observable modalContent = '';

  @observable modalContext = {};

  @observable modalProps = {};

  @observable pageTitle = '';

  @observable screenSize = '';

  @action openGlobalModal = (modalContent, modalContext = {}, modalProps = {}) => {
    this.isGlobalModalOpen = true;
    this.modalContent = modalContent;
    this.modalContext = modalContext;
    this.modalProps = modalProps;
  };

  @action closeGlobalModal = modalContext => {
    this.isGlobalModalOpen = false;
    this.modalContext = modalContext || {};
  };

  @action setCallsCount = count => {
    this.callsCount = count;
  };

  @action setEmrEncounter = emrEncounter => {
    this.emrEncounter = emrEncounter;
  };

  @action setScreenSize = screenSize => {
    this.screenSize = screenSize;
  };

  @action setPageTitle = pageTitle => {
    this.pageTitle = pageTitle;
  };
}

export default UIStore;
