import Cookies from 'js-cookie';
import { observable, action } from 'mobx';

class AuthStore {
  AUTH_TOKEN_KEY = 'authToken';

  MY_PROFILE = 'myProfile';

  ROLE_KEY = 'role';

  LOGIN_METHOD = 'loginMethod';

  @observable authToken = '';

  @observable clientSessionId = '';

  @observable loginMethod = '';

  @observable myProfile = null;

  @observable role = '';

  @observable isWsConnected = false;

  get isAuthenticated() {
    return !!this.myProfile;
  }

  @action logout = () => {
    Cookies.remove(this.AUTH_TOKEN_KEY);
    localStorage.removeItem(this.AUTH_TOKEN_KEY);
    this.authToken = '';

    Cookies.remove(this.ROLE_KEY);
    localStorage.removeItem(this.ROLE_KEY);
    this.role = '';

    Cookies.remove(this.MY_PROFILE);
    localStorage.removeItem(this.ROLE_KEY);
    this.myProfile = null;

    Cookies.remove(this.LOGIN_METHOD);
    localStorage.removeItem(this.LOGIN_METHOD);
    this.loginMethod = '';
  };

  @action setAuthToken = authToken => {
    localStorage.setItem(this.AUTH_TOKEN_KEY, authToken);
    Cookies.set(this.AUTH_TOKEN_KEY, authToken);
    this.authToken = authToken;
  };

  @action setClientSessionId = clientSessionId => {
    this.clientSessionId = clientSessionId;
  };

  @action setLoginMethod = loginMethod => {
    localStorage.setItem(this.LOGIN_METHOD, loginMethod);
    Cookies.set(this.LOGIN_METHOD, loginMethod);
    this.loginMethod = loginMethod;
  };

  @action setRole = role => {
    localStorage.setItem(this.ROLE_KEY, role);
    Cookies.set(this.ROLE_KEY, role);
    this.role = role;
  };

  @action setProfile = profile => {
    localStorage.setItem(this.MY_PROFILE, profile ? JSON.stringify(profile) : '');
    Cookies.set(this.MY_PROFILE, profile ? JSON.stringify(profile) : '');
    this.myProfile = profile;
  };

  @action setWsConnection = value => {
    this.isWsConnected = value;
  };
}

export default AuthStore;
