import { configure } from 'mobx';
import AuthStore from './AuthStore';
import CalendarStore from './CalendarStore';
import UIStore from './UIStore';
import ViewStore from './ViewStore';

configure({ enforceActions: 'observed' });

const authStore = new AuthStore();
const calendarStore = new CalendarStore();
const uiStore = new UIStore();
const viewStore = new ViewStore();

export default {
  authStore,
  calendarStore,
  uiStore,
  viewStore,
};
