import { observable, action } from 'mobx';

export default class ViewStore {
  @observable itemLists = [];

  listName = '';

  @observable itemList = [];

  @observable currentIndex = 0;

  @observable origin = null;

  @action setList = (name, list, currentIndex, origin) => {
    const itemLists = JSON.parse(localStorage.getItem('itemLists')) || [];

    const newList = {
      name,
      list,
      currentIndex,
      origin,
    };

    const existingList = itemLists.find(i => i.name === name);
    if (existingList) {
      const index = itemLists.findIndex(i => i.name === name);
      itemLists[index] = newList;
    } else {
      itemLists.push(newList);
    }
    localStorage.setItem('itemLists', itemLists ? JSON.stringify(itemLists) : '');
  };

  @action initializeViewStore = name => {
    const itemLists = JSON.parse(localStorage.getItem('itemLists'));
    const foundList = itemLists?.find(i => i.name === name);
    this.listName = foundList?.name;
    this.itemList = foundList?.list;
    this.origin = foundList?.origin || null;
    this.currentIndex = foundList?.currentIndex;
  };

  @action setCurrentIndex = index => {
    if (index >= 0 && index < this.itemList.length) {
      localStorage.setItem('currentIndex', index);
      this.currentIndex = index;
    }
  };

  @action getCurrentItem = () => {
    return this.itemList[this.currentIndex];
  };

  @action getNextItem = () => {
    if (this.currentIndex < this.itemList.length - 1) {
      return this.itemList[this.currentIndex + 1];
    }

    return null;
  };

  @action getPrevItem = () => {
    if (this.currentIndex > 0) {
      return this.itemList[this.currentIndex - 1];
    }

    return null;
  };
}
